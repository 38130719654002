jQuery(function ($) {
    var buynow = false;

    // get grand parent function
    $.fn.gparent = function (recursion) {
        if (recursion > 1)
            return $(this)
                .parent()
                .gparent(recursion - 1);
        return $(this).parent();
    };

    //woocommerce add to basket AJAX on product page

    $("body").on(
        "click",
        ".ajaxSubmission .single_add_to_cart_button",
        function (event) {
            event.preventDefault();
            buynow = false;
            var $thisbutton = $(this),
                $form = $thisbutton.closest("form.cart"),
                id = $thisbutton.val(),
                product_qty = $form.find("input[name=quantity]").val() || 1,
                product_id = $form.find("input[name=product_id]").val() || id,
                variation_id =
                    $form.find("input[name=variation_id]").val() || 0;

            var data = {
                action: "woocommerce_ajax_add_to_cart",
                product_id: product_id,
                product_sku: "",
                quantity: product_qty,
                variation_id: variation_id,
            };

            var bool = true;

            //check if form is a variable product and that an ID is selected by choosing attribute options
            if ($form.hasClass("variations_form")) {
                if (variation_id == "0") {
                    bool = false;
                }
            }

            if (bool == true) {
                $.ajax({
                    type: "post",
                    url: wc_add_to_cart_params.ajax_url,
                    data: data,
                    beforeSend: function (response) {
                        $(".ajaxmessage").slideUp();
                        $(".successmessage").slideUp();
                        $thisbutton.removeClass("added").addClass("loading");
                    },
                    complete: function (response) {
                        $thisbutton.addClass("added").removeClass("loading");
                    },
                    success: function (response) {
                        if (response.error) {
                            $(".ajaxmessage").slideDown();
                            $(".ajaxmessage").html(response.notices);
                        } else {
                            if ($thisbutton.gparent(2).hasClass("inmodal")) {
                                //for within modal
                                $(document.body).trigger("added_to_cart", [
                                    response.fragments,
                                    response.cart_hash,
                                    $thisbutton,
                                ]);
                                $thisbutton.hide();
                                var title = $thisbutton
                                    .gparent(2)
                                    .data("product");
                                $(".successmessage span.title").html(title);
                                $thisbutton
                                    .gparent(2)
                                    .append(
                                        '<p class="addedtocart position-relative"><span class="addedSuccess"></span>Added to Cart</p>'
                                    );
                                $(".successmessage").slideDown();
                            } else {
                                //for modal
                                $(document.body).trigger("added_to_cart", [
                                    response.fragments,
                                    response.cart_hash,
                                    $thisbutton,
                                ]);
                                $("#updateCartModal").modal("show");
                                $(".addedtocart").remove();
                                $(".inmodal .single_add_to_cart_button").show();
                                var title = $form.parent().data("product");
                                $(".successmessage span.title").html(title);
                                $(".successmessage").slideDown();
                            }

                            if (
                                $(".basket-item-count .cart-items-count").length
                            ) {
                                var currentValue = $(
                                    ".basket-item-count .cart-items-count"
                                ).html();
                            } else {
                                currentValue = 0;
                            }

                            var addition = product_qty;

                            var total =
                                parseInt(addition) + parseInt(currentValue);

                            var lastChild = $("button.cartButton")
                                .find("svg")
                                .nextAll(".basket-item-count")
                                .last();
                            if (lastChild.length > 0) {
                                lastChild.remove();
                            }

                            $("button.cartButton ")
                                .find("svg")
                                .after(
                                    '<div class="basket-item-count"><span class="cart-items-count text-white">' +
                                        total +
                                        "</span></div>"
                                );
                        }
                    },
                });
            }
        }
    );

    //refresh mini cart if back button is used
    $(window).on("pageshow", function (e) {
        if (e.originalEvent.persisted) {
            setTimeout(function () {
                $(".widget_shopping_cart_content").empty();
                $(document.body).trigger("wc_fragment_refresh");
            }, 100);
        }
    });
});
